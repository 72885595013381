import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import { ORDERS_RECEIVED_SHOW_ROUTE } from '@asaprint/asap/routes.js';
import { hasPermission, Permission } from '@asaprint/common/access.js';
import { ORDER_RECEIVED_PHASE_NAMES, phaseSx } from '@asaprint/common/constants/OrderReceived.js';
import FormatDate from '@engined/client/components/FormatDate.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { TIME_FORMAT } from '@engined/client/locales.js';
import { url } from '@engined/core/services/routes.js';
import { Edit } from '@mui/icons-material';
import { alpha, Box, Chip, IconButton, Link } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface TimeEntry {
  id: string;
  startAt: string;
  endAt?: string;
  orderReceived?: {
    id: string;
    moneyNumber?: string;
    moneyAddressName?: string;
    moneyName?: string;
  };
  product?: {
    id: string;
    name: string;
  };
  phase?: {
    name: string;
  };
  note?: string;
  user: {
    id: string;
  };
  orderReceivedPhase?: string;
}

interface OwnProps {
  timeEntry: TimeEntry;
  onEdit(event: React.MouseEvent<HTMLButtonElement>, id: string);
}

type Props = OwnProps;

const DayEvent: React.FunctionComponent<Props> = ({ timeEntry, onEdit }) => {
  const { t } = useLocale();
  const { requestUser } = useAuth();
  const canManageTimeEntries = hasPermission(requestUser.permissions as Permission[], Permission.TimeEntriesManage);
  const canManageTimeEntriesAll = hasPermission(
    requestUser.permissions as Permission[],
    Permission.TimeEntriesManageAll,
  );

  const onEditCallback = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    onEdit(event, timeEntry.id);
  });

  return (
    <Box
      color="#fff"
      borderRadius={1}
      p={1}
      bgcolor={(theme) => alpha(theme.palette.primary.main, 0.5)}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      flexGrow={1}
      height="100%"
      lineHeight="1em"
      minHeight="calc(2em + 10px)"
      gap={2}
    >
      <Box>
        <FormatDate date={timeEntry.startAt} format={TIME_FORMAT} /> -{' '}
        <FormatDate date={timeEntry.endAt} format={TIME_FORMAT} />
      </Box>
      {timeEntry.orderReceived && (
        <Link
          component={RouterLink}
          to={url(ORDERS_RECEIVED_SHOW_ROUTE, { id: timeEntry.orderReceived.id })}
          sx={{ color: 'inherit' }}
        >
          {timeEntry.orderReceived.moneyNumber} - {timeEntry.orderReceived.moneyName} -{' '}
          {timeEntry.orderReceived.moneyAddressName}
        </Link>
      )}{' '}
      {timeEntry.product && (
        <Link
          component={RouterLink}
          to={url(ORDERS_RECEIVED_SHOW_ROUTE, { id: timeEntry.orderReceived.id }) + `#product-${timeEntry.product.id}`}
          sx={{ color: 'inherit' }}
        >
          {timeEntry.product.name}
        </Link>
      )}{' '}
      {timeEntry.phase && <span>{timeEntry.phase.name}</span>}
      {timeEntry.orderReceivedPhase && (
        <Chip
          sx={{ ...phaseSx[timeEntry.orderReceivedPhase] }}
          label={ORDER_RECEIVED_PHASE_NAMES[timeEntry.orderReceivedPhase]}
        />
      )}
      {timeEntry.note && <div>{timeEntry.note}</div>}
      {(canManageTimeEntriesAll || (canManageTimeEntries && timeEntry.user.id === requestUser.id)) && (
        <IconButton onClick={onEditCallback}>
          <Edit />
        </IconButton>
      )}
    </Box>
  );
};

DayEvent.displayName = 'DayEvent';

export default React.memo<Props>(DayEvent);
