import useQuery from '@engined/client/hooks/useQuery.js';
import DayEvent from '@asaprint/asap/components/DayEvent.js';
import CreateTimeEntryDialog from '@asaprint/asap/components/dialogs/CreateTimeEntryDialog.js';
import EditTimeEntryDialog from '@asaprint/asap/components/dialogs/EditTimeEntryDialog.js';
import PageHeader from '@asaprint/asap/components/PageHeader.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import authorized from '@asaprint/asap/decorators/authorized.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import { DASHBOARD_ROUTE } from '@asaprint/asap/routes.js';
import { DayView_Load } from '@asaprint/asap/routes/__authenticated/day-view.graphql';
import {
  DayView_LoadQuery,
  DayView_LoadQueryVariables,
  DayView_TimeEntryFragment,
} from '@asaprint/asap/schema.client.types.js';
import { hasPermission, Permission } from '@asaprint/common/access.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import { Option } from '@engined/client/components/forms/fields/AutocompleteField.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import useDialog from '@engined/client/hooks/useDialog.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { DATE_FORMAT } from '@engined/client/locales.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { faCalendarDay, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Autocomplete, Box, Button, FormControlLabel, IconButton, Paper, Switch, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { addDays, endOfDay, parse, startOfDay } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const MS_IN_MINUTE = 60 * 1000;
const MS_IN_INTERVAL = 15 * MS_IN_MINUTE;

interface TimeEvent {
  start: number;
  end: number;
  timeEntry: DayView_TimeEntryFragment;
  index: number;
  overlaps: number;
}

interface Board {
  [interval: string]: {
    [slot: string]: TimeEvent;
  };
}

interface OwnProps {}

type Props = OwnProps;

function minutesToHours(minutes) {
  return `${Math.floor(minutes / 60)
    .toString()
    .padStart(2, '0')}:${(minutes % 60).toString().padStart(2, '0')}`;
}

function canInsertAt(board: Board, index: number, start: number, end: number): boolean {
  for (let i = Math.floor(start / MS_IN_INTERVAL) * MS_IN_INTERVAL; i < end; i += MS_IN_INTERVAL) {
    const row = board[i / MS_IN_MINUTE] ?? {};
    if (row[index]) {
      return false;
    }
  }

  return true;
}

function findBoardIndex(board: Board, start: number, end: number): number {
  let index = 0;
  while (!canInsertAt(board, index, start, end)) {
    index++;
  }
  return index;
}

const refetchQueriesOnEdit = ['DayView_Load'];

const DayView: React.FunctionComponent<Props> = () => {
  const { t } = useLocale();
  const { requestUser } = useAuth();
  const [query] = useSearchParams();
  const [currentDay, setCurrentDay] = useState(() => {
    if (!query.has('date')) {
      return new Date();
    }

    const d = query.get('date');
    const iso = parse(d, 'yyyy-MM-dd', new Date());
    if (!isNaN(iso.getTime())) {
      return iso;
    }

    const local = parse(d, t(DATE_FORMAT), new Date());
    return isNaN(local.getTime()) ? new Date() : local;
  });
  const [showWholeDay, setShowWholeDay] = useState(false);
  const canReadAllTimeEntries = hasPermission(requestUser.permissions as Permission[], Permission.TimeEntriesReadAll);
  const [user, setUser] = useState<string>(
    canReadAllTimeEntries && query.has('user') ? query.get('user') : requestUser.id,
  );
  const {
    open: createTimeEntryDialogOpen,
    onOpen: createTimeEntryDialogOnOpen,
    onClose: createTimeEntryDialogOnClose,
  } = useDialog<boolean>(false);
  const {
    open: editTimeEntryDialogOpen,
    value: editTimeEntryDialogValue,
    onOpen: editTimeEntryDialogOnOpen,
    onClose: editTimeEntryDialogOnClose,
  } = useDialog<string>(null);

  const intervals = useMemo(() => {
    if (showWholeDay) {
      return Array.from(Array(24 * 4).keys()).map((i) => i * 15);
    } else {
      return Array.from(Array(13 * 4).keys()).map((i) => i * 15 + 6 * 4 * 15);
    }
  }, [showWholeDay]);

  const { loading, data, error } = useQuery<DayView_LoadQuery, DayView_LoadQueryVariables>(DayView_Load, {
    variables: {
      filter: {
        user: {
          // Convert to undefined to ignore value
          id: { eq: user ?? requestUser.id },
        },
        startAt: {
          lte: endOfDay(currentDay).toISOString(),
        },
        endAt: {
          gte: startOfDay(currentDay).toISOString(),
        },
      },
    },
  });

  const users = data?.users?.edges;
  const usersOptions = useMemo<Option[]>(
    () =>
      users
        ? users.map((e) => ({
            label: displayName(e.node),
            value: e.node.id,
          }))
        : [],
    [users],
  );

  const onNextDayClick = useEventCallback(() => {
    setCurrentDay((s) => addDays(s, 1));
  });

  const onPreviousDayClick = useEventCallback(() => {
    setCurrentDay((s) => addDays(s, -1));
  });

  const productTimeEntriesEdges = data?.timeEntries?.edges;
  const startOfTheDay = intervals[0] * MS_IN_MINUTE;
  const endOfTheDay = intervals[intervals.length - 1] * MS_IN_MINUTE + MS_IN_INTERVAL;
  const timeEvents = useMemo(() => {
    if (!productTimeEntriesEdges?.length) {
      return [];
    }

    const productTimeEntries = productTimeEntriesEdges.map((e) => e.node);
    const startOfCurrentDayInMs = startOfDay(currentDay).getTime();

    const events: TimeEvent[] = productTimeEntries
      .map((t) => {
        const end = Math.min(new Date(t.endAt).getTime() - startOfCurrentDayInMs, endOfTheDay);
        const start = Math.max(new Date(t.startAt).getTime() - startOfCurrentDayInMs, startOfTheDay);
        return {
          timeEntry: t,
          start,
          end,
          index: 0,
          overlaps: 1,
        };
      })
      .sort((a, b) => {
        const cmp = a.start - b.start;
        return cmp === 0 ? b.end - a.end : cmp;
      });

    const board: Board = {};
    for (const ev of events) {
      const index = findBoardIndex(board, ev.start, ev.end);
      for (let i = Math.floor(ev.start / MS_IN_INTERVAL) * MS_IN_INTERVAL; i < ev.end; i += MS_IN_INTERVAL) {
        const row = board[i / MS_IN_MINUTE] ?? {};
        row[index] = ev;
        board[i / MS_IN_MINUTE] = row;
        ev.index = index;
        if (ev.overlaps < index + 1) {
          ev.overlaps = index + 1;
        }
      }
    }

    // Set max overlaps to items in the same row
    for (const [i, row] of Object.entries(board)) {
      const maxOverlaps = Math.max(...Object.values(row).map((r) => r.overlaps));
      for (const e of Object.values(row)) {
        if (e.overlaps < maxOverlaps) {
          e.overlaps = maxOverlaps;
        }
      }
    }

    // We need second pass
    for (const [i, row] of Object.entries(board)) {
      const maxOverlaps = Math.max(...Object.values(row).map((r) => r.overlaps));
      for (const e of Object.values(row)) {
        if (e.overlaps < maxOverlaps) {
          e.overlaps = maxOverlaps;
        }
      }
    }

    return events;
  }, [productTimeEntriesEdges, currentDay, startOfTheDay, endOfTheDay]);

  const userOption = usersOptions.find((o) => o.value === user) ?? null;

  const onDateChangeCallback = useEventCallback((d: Date) => {
    setCurrentDay(d);
  });

  return (
    <>
      <PageHeader
        title={
          <>
            <FontAwesomeIcon icon={faCalendarDay} /> Timesheet
          </>
        }
        actions={
          <Box display="flex" justifyContent="flex-end">
            <Button color="primary" onClick={createTimeEntryDialogOnOpen} variant="contained">
              <FontAwesomeIcon icon={faPlus} />
              &nbsp; Nový záznam
            </Button>
          </Box>
        }
      />

      <Box display="flex" justifyContent="flex-end" alignItems="center" flexDirection={{ xs: 'column', md: 'row' }}>
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={showWholeDay}
                onChange={(event, checked) => {
                  setShowWholeDay(checked);
                }}
              />
            }
            label="Zobraziť celý deň"
            sx={{ mr: 4 }}
          />
        </Box>
        {canReadAllTimeEntries && (
          <Box>
            <Autocomplete
              options={usersOptions}
              value={userOption}
              renderInput={(props) => <TextField {...props} label="Používateľ" sx={{ minWidth: 200 }} />}
              onChange={(event, value) => {
                setUser(value?.value);
              }}
              disableClearable
            />
          </Box>
        )}
        <Box display="flex" alignItems="center" mt={{ xs: 2, md: 0 }} ml={{ xs: 0, md: 4 }} gap={1}>
          <IconButton size="small" onClick={onPreviousDayClick}>
            <ChevronLeft />
          </IconButton>

          <DatePicker value={currentDay} format={t(DATE_FORMAT)} onChange={onDateChangeCallback} />

          <IconButton size="small" onClick={onNextDayClick}>
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>

      <Paper sx={{ mb: 5, mt: 2 }}>
        <Box minWidth={1024} p={5}>
          <Box position="relative">
            <Box border={1} borderColor="#eee">
              {intervals.map((interval) => (
                <Box
                  key={interval}
                  height={90}
                  sx={{
                    [`&:nth-child(2n + 1)`]: {
                      backgroundColor: 'rgba(0, 0, 0, 0.03)',
                    },
                  }}
                >
                  <Box height="100%" px={2} py={1} color="#555" display="flex" alignItems="center">
                    {minutesToHours(interval)}
                  </Box>
                  <Box height="100%" px={2} py={1} />
                </Box>
              ))}
            </Box>
            <Box ml={14} position="absolute" sx={{ inset: 0 }}>
              {timeEvents.map(({ start, index, end, overlaps, timeEntry }) => (
                <Box
                  key={timeEntry.id}
                  position="absolute"
                  px={1}
                  style={{
                    top: `${((start - startOfTheDay) / (endOfTheDay - startOfTheDay)) * 100}%`,
                    left: `${(index / overlaps) * 100}%`,
                    bottom: `${(1 - (end - startOfTheDay) / (endOfTheDay - startOfTheDay)) * 100}%`,
                    right: `${(1 - (index + 1) / overlaps) * 100}%`,
                  }}
                >
                  <DayEvent timeEntry={timeEntry} onEdit={editTimeEntryDialogOnOpen} />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Paper>
      <CreateTimeEntryDialog onClose={createTimeEntryDialogOnClose} open={createTimeEntryDialogOpen} />
      <EditTimeEntryDialog
        onClose={editTimeEntryDialogOnClose}
        open={editTimeEntryDialogOpen}
        timeEntryId={editTimeEntryDialogValue}
        refetchQueries={refetchQueriesOnEdit}
      />
    </>
  );
};

export default authorized(Permission.TimeEntriesRead)(DayView);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs<'id'>) => {
  if (ENV.SERVER) {
    const day = req.query.date ? new Date(req.query.date as string) : new Date();
    const result = await apollo.query<DayView_LoadQuery, DayView_LoadQueryVariables>({
      query: DayView_Load,
      variables: {
        filter: {
          user: {
            id: { eq: (req.query.user as string) ?? req.user.id },
          },
          startAt: {
            lte: endOfDay(day).toISOString(),
          },
          endAt: {
            gte: startOfDay(day).toISOString(),
          },
        },
      },
    });
    return result.data;
  }
  return null;
};

export const handle: RouteHandle = {
  breadcrumbs: [
    { text: 'Dashboard', to: DASHBOARD_ROUTE },
    {
      text: 'Denný timesheet',
    },
  ],
  meta: ({ locale: { t }, meta }: MetaFunctionArgs) => ({
    title: `Denný timesheet | ${meta.title}`,
  }),
};
